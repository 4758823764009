import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Container, Row, Col, Label, FormGroup, Input } from "reactstrap"
import MailchimpSubscribe from "react-mailchimp-subscribe"
import styled, { css } from "styled-components"
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"
import Button from "components/shared/Button"
import Text from "components/shared/Text"

const ContainerMaxStyled = styled(ContainerMax)`
    background-color:${props => props.theme.colors.whiteOff};
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @media ${media.md} {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    label {
        font-size: ${props => props.theme.font.size.sm};
        color: ${props => props.theme.colors.secondary};
        margin: 0;
    }

    h1, h2, h3 {
		font-size: 1.125rem;/*18*/
		font-family: ${props => props.theme.font.family.base};
		color: ${props => props.theme.colors.grey1};
		line-height: 1.44;
		color: #1D2E41;
		margin: 12px 0 16px;
		font-weight: 400;	
		
	    @media ${media.md} {
			font-size: 1.5rem;/*24*/
			margin: 45px 0 50px;
			line-height: 1.5;
	    }
    }

    .terms {
        p {
            color: ${props => props.theme.colors.primary};
        }
    }

    a {
        color: ${props => props.theme.colors.yellow};

        &:hover {
            text-decoration: underline;
        }
    }
`

const FormWrap = styled.div`
    padding-top: 1rem;
`

const StyledInput = styled.input`
    background-color: transparent;
    color: ${props => props.theme.colors.grey1};
    background-color: ${props => props.theme.colors.white};
    padding: .15rem 1rem;
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 0;

    &::placeholder {
        color: ${props => props.theme.colors.grey3};
    }
`

const SubmitButton = styled(Button)`
    margin-top: 1.25rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
`

const ErrorMessage = styled.div`
    color: ${props => props.theme.colors.redLight}; 
    font-size: ${props => props.theme.font.size.sm}; 
    text-align: right;
    padding-top: .2rem;

    a {
        color: ${props => props.theme.colors.yellow};
    }

    ${props => props.left && css`
        text-align: left;
    `}
`

class NewsletterForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            FNAME: "",
            LNAME: "",
            EMAIL: "",
            termsChecked: false,
            termsError: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidUpdate(previousProps) {
        if (previousProps.status !== this.props.status) {
            if (this.props.status === "success") {
                this.setState({
                    FNAME: "",
                    LNAME: "",
                    EMAIL: "",
                    termsChecked: false,
                    termsError: false
                })
            }
        }
    }

    formatMessage(message) {
        if (message.includes("0 - Please enter a value")) {
            return "Please enter your email address."
        }

        if (message.includes("An email address must contain")) {
            return "Please enter a valid email address."
        }

        if (message.includes("The domain portion of the email address is invalid")) {
            return "Please enter a valid email address."
        }

        if (message.includes("This email cannot be added to this list")) {
            return "This email address is already registered. Please enter a different email address."
        }

        return message
    }

    handleChange(e) {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        // Update value in state
        this.setState({
            [name]: value,
        });
    }

    handleCheckboxChange(e) {
        this.setState({ termsChecked: e.target.checked })
    }        

    handleSubmit() {
        if (this.state.termsChecked) {
            this.setState({
                termsError: false
            })
            this.props.onValidated({
                FNAME: this.state.FNAME,
                LNAME: this.state.LNAME,
                EMAIL: this.state.EMAIL
            });
        } else {
            this.setState({ 
                termsError: true
            })
        }
    }

    render() {
        return (
            <FormWrap>
                <Row>
                    <Col xs={12} lg={10}>
                        <Row>
                            <Col xs={12} md={6} lg={4} className="pb-2 pb-lg-0">
                                <Label for="FNAME">FIRST NAME</Label>
                                <StyledInput
                                    type="text"
                                    name="FNAME"
                                    id="FNAME"
                                    placeholder="Your first name"
                                    value={this.state.FNAME}
                                    onChange={e => {
                                        this.handleChange(e)
                                    }}
                                />
                            </Col>
                            <Col xs={12} md={6} lg={4} className="pb-2 pb-lg-0">
                                <Label for="FNAME">LAST NAME</Label>
                                <StyledInput
                                    type="text"
                                    name="LNAME"
                                    id="LNAME"
                                    placeholder="Your last name"
                                    value={this.state.LNAME}
                                    onChange={e => {
                                        this.handleChange(e)
                                    }}
                                />
                            </Col>
                            <Col xs={12} lg={4} className="pb-2 pb-lg-0">
                                <Label for="EMAIL">EMAIL*</Label>
                                <StyledInput
                                    type="email"
                                    name="EMAIL"
                                    id="EMAIL"
                                    placeholder="Your email address"
                                    value={this.state.EMAIL}
                                    onChange={e => {
                                        this.handleChange(e)
                                    }}
                                />
                            </Col>
                            {this.props.status === "error" &&
                                <Col xs={12}>   
                                    <ErrorMessage dangerouslySetInnerHTML={{ __html: this.formatMessage(this.props.message) }} />
                                </Col>
                            }
                        </Row>
                    </Col>
                    <Col xs={12} md={{ order: 3 }} className="pt-4">
                        <FormGroup check>
                            <Label check className="terms">
                                <Row>
                                    <Col xs="auto">
                                        <Input type="checkbox" id="terms" onChange={this.handleCheckboxChange} checked={this.state.termsChecked} />{' '}
                                    </Col>
                                    <Col xs="auto">
                                        <div dangerouslySetInnerHTML={{ __html: this.props.termsText }} />
                                    </Col>
                                </Row>
                                {this.state.termsError &&
                                    <ErrorMessage left>Please accept our terms.</ErrorMessage>
                                }
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={2} md={{ order: 1 }} className="d-flex align-items-center">
                        <SubmitButton onClick={this.handleSubmit} as="button" color="primary" disabled={this.state.EMAIL === ""}>
                            {this.props.buttonText}
                        </SubmitButton>
                    </Col>
                    {this.props.status === "success" && (
                        <Col xs={12} md={{ order: 2 }} className="pt-4">
                            <h4 className="mt-4 m-md-0">
                                <div dangerouslySetInnerHTML={{ __html: this.props.successText }} />
                            </h4>
                        </Col>
                    )}
                </Row>
            </FormWrap>
        )
    }
}

const NewsletterSignup = (props) => {
    const url = "https://pncfathersonchallenge.us20.list-manage.com/subscribe/post?u=c08a44b7ccab609593ed22d0b&amp;id=fa31eb18f1"

    return (
        <ContainerMaxStyled maxWidth="1920" id="newsletter-signup">
            <Container>
                <Text as="div" primary dangerouslySetInnerHTML={{ __html: props.data.text }} />
                <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                        <>
                            <NewsletterForm
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                                termsText={props.data.termsText}
                                buttonText={props.data.buttonText}
                                successText={props.data.successText}
                            />
                        </>
                    )}
                />
            </Container>
        </ContainerMaxStyled>
    )
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                allWp {
                    edges {
                        node {
                            options {
                                acfOptions {
                                    blockNewsletterSignup {
                                        text
                                        termsText
                                        buttonText
                                        successText
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            return (
                <NewsletterSignup data={data.allWp.edges[0].node.options.acfOptions.blockNewsletterSignup} />
            )
        }}
    />
)